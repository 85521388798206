import { content } from '../../content';
import Logo from '../Logo';
import style from './Footer.module.scss';
import { toAnchor } from '../../helpers/toAnchor';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const navigateTo = (link) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate(link);
  };

  return (
    <footer className={style.footer}>
      <div className='container'>
        <div className={style.footerBody}>
          <div className={style.footerCol}>
            <Logo black className={style.footerCol__logo} />
            <div className={style.footerCol__text}>{content.footer.text}</div>
            <div className={style.footerCol__cr}>{content.footer.cr}</div>
            <div className={style.footerCol__cr}>{content.footer.ip}</div>
            <a
              href={`mailto:${content.footer.mail}`}
              className={style.footerCol__privacy}
            >
              Email: {content.footer.mail}
            </a>
            <div
              onClick={() => navigateTo(content.footer.privacy.link)}
              className={style.footerCol__privacy}
            >
              {content.footer.privacy.text}
            </div>
          </div>
          <div className={style.footerCol}>
            <div className={style.footerCol__title}>Навигация</div>
            <div className={style.footerCol__links}>
              {content.headerLinks.map((item, index) => (
                <div
                  key={index}
                  onClick={() => toAnchor(item.to)}
                  className={style.footerCol__linksItem}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div className={style.footerSocial}>
            Мы в соц. сетях:
            {content.socialLinks.map((item, index) => (
              <a key={index} target='_blank' rel='noreferrer' href={item.href}>
                {item.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
