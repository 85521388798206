import { useEffect } from 'react';
import style from './JellyButton.module.scss';
import LiquidButton from '../../lib/JellyButton';

const JellyButton = ({ onClick, className, title }) => {
  useEffect(() => {
    const buttons = document.getElementsByClassName('liquid-button');
    for (let buttonIndex = 0; buttonIndex < buttons.length; buttonIndex++) {
      const button = buttons[buttonIndex];
      button.liquidButton = new LiquidButton(button);
    }
  });

  return (
    <svg
      onClick={onClick}
      className={`${style.btn} ${className} liquid-button`}
      data-text={title}
      data-force-factor='0.1'
      data-layer-1-viscosity='0.5'
      data-layer-2-viscosity='0.4'
      data-layer-1-mouse-force='400'
      data-layer-2-mouse-force='500'
      data-layer-1-force-limit='1'
      data-layer-2-force-limit='2'
      data-color1='#78ebff'
      data-color2='#4985ff'
      data-color3='#20D8F9'
      data-textColor='#fff'
    ></svg>
  );
};

export default JellyButton;
