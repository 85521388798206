import { useMediaQuery } from 'usehooks-ts';
import SectionTitle from '../../../../components/SectionTitle';
import { content } from '../../../../content';
import style from './Services.module.scss';
import Card from './components/Card';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './slider.scss';

const ServicesSection = () => {
  const sectionContent = content.homePage.servicesSection;
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section id='services' className={style.services}>
      <div className='container'>
        <SectionTitle
          className={style.services__title}
          label={sectionContent.label}
          title={sectionContent.title}
        />
        {!isMobile && (
          <div className={style.servicesBody}>
            {sectionContent.list.map((item, index) => (
              <Card
                type={item.type}
                key={index}
                name={item.name}
                price={item.price}
                descr={item.description}
              />
            ))}
          </div>
        )}
        {isMobile && (
          <div className='services-slider'>
            <Swiper
              modules={[Pagination]}
              className={style.servicesSlider}
              spaceBetween={10}
              slidesPerView={1}
              autoHeight
              pagination={{
                el: '#services-slider-pagination',
                clickable: true,
              }}
            >
              {sectionContent.list.map((item, index) => (
                <SwiperSlide key={index}>
                  <Card
                    type={item.type}
                    name={item.name}
                    price={item.price}
                    descr={item.description}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div id='services-slider-pagination'></div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ServicesSection;
