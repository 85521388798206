import { Fade } from 'react-reveal';
import StatsCard from '../../components/StatsCard/StatsCard';
import style from './Stats.module.scss';

const Stats = ({ content }) => {
  return (
    <Fade bottom>
      <div className={style.stats}>
        {content.map((item, index) => (
          <StatsCard
            key={index}
            icon={item.icon}
            value={item.value}
            label={item.label}
          />
        ))}
      </div>
    </Fade>
  );
};

export default Stats;
