const avitoIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='800px'
    height='800px'
    viewBox='0 0 192 192'
    fill='none'
  >
    <g style={{ strokeWidth: 18.63157, strokeDasharray: 'none' }}>
      <path
        fill='#04e061'
        d='M61.924 172.07c26.468 0 47.924-21.456 47.924-47.924S88.392 76.222 61.924 76.222 14 97.678 14 124.146s21.456 47.925 47.924 47.925z'
        style={{
          fill: 'none',
          fillOpacity: 1,
          strokeWidth: 18.63157,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        transform='translate(7.024 7.024) scale(.92683)'
      />

      <path
        fill='#ff4053'
        d='M149.044 166.007c15.992 0 28.956-12.963 28.956-28.955 0-15.992-12.964-28.955-28.956-28.955-15.99 0-28.954 12.963-28.954 28.955 0 15.992 12.963 28.955 28.954 28.955z'
        style={{
          fill: 'none',
          fillOpacity: 1,
          strokeWidth: 18.63157,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        transform='translate(7.024 7.024) scale(.92683)'
      />

      <path
        fill='#965eeb'
        d='M71.086 65.979c9.898 0 17.922-8.024 17.922-17.921 0-9.898-8.024-17.922-17.922-17.922-9.898 0-17.921 8.024-17.921 17.922 0 9.897 8.023 17.921 17.921 17.921z'
        style={{
          fill: 'none',
          fillOpacity: 1,
          strokeWidth: 18.63157,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        transform='translate(7.024 7.024) scale(.92683)'
      />

      <path
        fill='#0af'
        d='M137.789 97.856c21.519 0 38.964-17.444 38.964-38.963 0-21.52-17.445-38.964-38.964-38.964-21.52 0-38.964 17.445-38.964 38.964s17.445 38.963 38.964 38.963z'
        style={{
          fill: 'none',
          fillOpacity: 1,
          strokeWidth: 18.63157,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        transform='translate(7.024 7.024) scale(.92683)'
      />
    </g>
  </svg>
);

export default avitoIcon;
