import { Fade } from 'react-reveal';
import style from './Card.module.scss';

const Card = ({ className, img, name, link, isBest, type }) => {
  return (
    <Fade>
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
        className={`${className} ${isBest && style.best} ${style.card}`}
      >
        <img loading='lazy' src={`./assets/img/portfolio/${img}.jpg`} alt='' />
        {/* <picture>
          <source
            type='image/webp'
            srcSet={`./assets/img/portfolio/${img}.webp`}
          />
          <source
            type='image/jpeg'
            srcSet={`./assets/img/portfolio/${img}.jpg`}
          />
          <img src={`./assets/img/portfolio/${img}.jpg`} alt='' />
        </picture> */}
        <div className={style.card__name}>
          {name}
          <svg
            width='13'
            height='13'
            viewBox='0 0 13 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <line
              x1='11.8691'
              y1='2.18556e-08'
              x2='11.8691'
              y2='12'
              stroke='white'
            />
            <line x1='12' y1='0.5' y2='0.5' stroke='white' />
            <line
              x1='11.7227'
              y1='0.984901'
              x2='0.985851'
              y2='11.7217'
              stroke='white'
            />
          </svg>
        </div>
      </a>
    </Fade>
  );
};

export default Card;
