import { Fade } from 'react-reveal';
import { content } from '../../../../content';
import style from './Form.module.scss';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useReadLocalStorage } from 'usehooks-ts';
import { Link, useNavigate } from 'react-router-dom';

const FormSection = () => {
  const sectionContent = content.homePage.formSection;
  const thisForm = useRef(null);
  const siteTypeInputRef = useRef(null);
  const [isSent, setSent] = useState(false);
  const siteTypeInputValue = useReadLocalStorage('siteTypeInputValue');
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let formData = new FormData(thisForm.current);
    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        setSent(true);

        if (xhr.status === 200) {
          console.log('Mail send');
        }

        setTimeout(() => {
          setSent(false);
          setTimeout(() => {
            thisForm.current.reset();
          }, 0);
        }, 5000);
      }
    };

    // xhr.open('POST', 'mail.php', true);
    xhr.open('POST', 'mail.php', true);
    xhr.send(formData);

    // thisForm.current.reset();
  };

  const toPrivacy = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate('privacy');
  };

  return (
    <section id='form' className={style.form}>
      <div className='container'>
        <Fade>
          <div className={style.formBody}>
            <Fade>
              <div className={style.formBody__img}>
                <img src={sectionContent.img} alt='' />
              </div>
            </Fade>
            <div className={style.formBody__text}>
              {!isSent && (
                <>
                  <Fade>
                    <div className={style.formBody__textTitle}>
                      {sectionContent.title}
                    </div>
                  </Fade>
                  <Fade>
                    <div className={style.formBody__textLabel}>
                      {sectionContent.label}
                    </div>
                  </Fade>
                  <form
                    ref={thisForm}
                    onSubmit={handleSubmit(onSubmit)}
                    className={style.formBody__textForm}
                  >
                    <input
                      hidden
                      ref={siteTypeInputRef}
                      id='site-type-input'
                      value={siteTypeInputValue ? siteTypeInputValue : 'empty'}
                      type='text'
                      {...register('siteType', { required: false })}
                    />
                    <Fade bottom>
                      <label className={`${errors.name && style._err}`}>
                        <input
                          type='text'
                          autoComplete='given-name'
                          placeholder='Имя*'
                          {...register('name', { required: true })}
                        />
                      </label>
                    </Fade>
                    <Fade bottom>
                      <label className={`${errors.email && style._err}`}>
                        <input
                          type='text'
                          autoComplete='email'
                          placeholder='E-mail*'
                          {...register('email', {
                            required: true,
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: '',
                            },
                          })}
                        />
                      </label>
                    </Fade>
                    <Fade bottom>
                      <label className={`${errors.tel && style._err}`}>
                        {/* <input
                          {...register('tel', { required: false })}
                          type='text'
                        /> */}

                        <InputMask
                          mask='+999999999999'
                          {...register('tel', { required: false })}
                          autoComplete='tel'
                          placeholder='Телефон'
                        />
                      </label>
                    </Fade>
                    <Fade bottom>
                      <textarea
                        {...register('message', { required: false })}
                        placeholder='Краткое описание проекта'
                      ></textarea>
                    </Fade>
                    <Fade bottom>
                      <label
                        className={`${style.policy} ${
                          errors.policyAccept && style._err
                        }`}
                      >
                        <input
                          {...register('policyAccept', { required: true })}
                          defaultChecked
                          type='checkbox'
                        />
                        <span>
                          Вы соглашаетесь на{' '}
                          <div onClick={toPrivacy}>
                            условия обработки персональных данных
                          </div>
                        </span>
                      </label>
                    </Fade>
                    <Fade bottom>
                      <button>Отправить заявку</button>
                    </Fade>
                  </form>
                </>
              )}
              {isSent && (
                <div className={style.formBody__thanks}>
                  <div className={style.formBody__thanksIcon}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      class='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M4.5 12.75l6 6 9-13.5'
                      />
                    </svg>
                  </div>
                  <div className={style.formBody__thanksTitle}>
                    <Fade>{sectionContent.thanks.title}</Fade>
                  </div>
                  <div className={style.formBody__thanksText}>
                    <Fade>{sectionContent.thanks.text}</Fade>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default FormSection;
