import style from './Card.module.scss';
import { toAnchor } from '../../../../../../helpers/toAnchor';
import { Fade } from 'react-reveal';
import { useLocalStorage } from 'usehooks-ts';

const Card = ({ name, price, descr, type, className }) => {
  const [siteTypeInputValue, setSiteTypeInputValue] = useLocalStorage(
    'siteTypeInputValue',
    ''
  );

  const selectItem = (name) => {
    // const typeInput = document.querySelector('#site-type-input');
    // typeInput.value = name;
    toAnchor('form');
    setSiteTypeInputValue(name);
  };

  return (
    <Fade>
      <div className={`${style.card} ${className}`}>
        <div className={style.card__name}>
          <Fade>{name}</Fade>
        </div>
        <Fade>
          <div className={style.card__price}>
            <span>От </span>
            {price.toLocaleString()} ₽
          </div>
        </Fade>
        <div className={style.card__descr}>
          <Fade>{descr}</Fade>
        </div>
        <Fade>
          <div onClick={() => selectItem(type)} className={style.card__btn}>
            Заказать
          </div>
        </Fade>
      </div>
    </Fade>
  );
};

export default Card;
