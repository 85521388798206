import styles from './Home.module.scss';
import AboutSection from './Modules/About';
import MainSection from './Modules/Main';
import PortfolioSection from './Modules/Portfolio';
import ServicesSection from './Modules/Services';
import WhySection from './Modules/Why';
import FormSection from './Modules/Form';

const HomePage = () => {
  return (
    <>
      <MainSection />
      <AboutSection />
      <ServicesSection />
      <WhySection />
      <PortfolioSection />
      <FormSection />
    </>
  );
};

export default HomePage;
