export const toAnchor = (anchor) => {
  if (anchor === '/') {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
    const block = document.querySelector(`#${anchor}`);
    block.scrollIntoView({ behavior: 'smooth' });
  }
};
