import style from './Portfolio.module.scss';
import SectionTitle from '../../../../components/SectionTitle';
import { content } from '../../../../content';
import Card from './components/card';
import JellyButton from '../../../../components/JellyButton';
import { useMediaQuery } from 'usehooks-ts';
import { Fade } from 'react-reveal';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toAnchor } from '../../../../helpers/toAnchor';

const PortfolioSection = () => {
  const isMobile = useMediaQuery('(max-width:500px)');
  const [projectsCount, setProjectsCount] = useState(isMobile ? 3 : 6);
  const [projectsPage, setProjectsPage] = useState(1);

  const projectsListState = useSelector((state) => state.projects);

  // Projects list
  const projectsList = projectsListState
    .map((item, index) => (
      <Card
        key={index}
        img={item.Img}
        name={item.Name}
        link={item.Link}
        isBest={item.IsBest}
        type={item.Type}
        className={style.portfolioBody__card}
      />
    ))
    .reverse();

  const showMore = () => {
    const nextPage = projectsPage + 1;
    setProjectsPage(nextPage);
    setProjectsCount(isMobile ? 3 * nextPage : 6 * nextPage);
  };

  const showLess = () => {
    toAnchor('portfolio');

    setTimeout(() => {
      setProjectsPage(1);
      setProjectsCount(isMobile ? 3 : 6);
    }, 300);
  };

  useEffect(() => {
    setProjectsCount(isMobile ? 3 * projectsPage : 6 * projectsPage);
  }, [isMobile]);

  return (
    <section id='portfolio' className={style.portfolio}>
      <div className={`${style.con} container`}>
        <SectionTitle
          className={style.portfolio__title}
          title={content.homePage.portfolioSection.title}
        />
        <div className={style.portfolioBody}>
          {projectsList.slice(0, projectsCount)}
        </div>
        <div className={style.portfolioBottom}>
          {projectsPage > 1 && (
            <div onClick={showLess} className={style.portfolioBottom__clear}>
              Скрыть
            </div>
          )}
          <Fade>
            <JellyButton
              onClick={showMore}
              className={style.portfolioBottom__btn}
              title={'Больше проектов'}
            />
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
