import { Fade } from 'react-reveal';
import { content } from '../../../../content';
import style from './About.module.scss';
import img from './assets/img.jpg';
import img2x from './assets/img@2x.jpg';
import Stats from './modules/Stats';

const AboutSection = () => {
  const sectionContent = content.homePage.aboutSection;

  return (
    <section id='about' className={style.about}>
      <div className={`${style.con} container`}>
        <Fade>
          <div className={style.aboutBody}>
            <div className={style.aboutBody__text}>
              <Fade>
                <div className={style.aboutBody__textTitle}>
                  {sectionContent.title}
                </div>
              </Fade>
              <Fade>
                <div className={style.aboutBody__textDescr}>
                  {sectionContent.description}
                </div>
              </Fade>
            </div>
            <Fade>
              <div className={style.aboutBody__img}>
                <img src={img2x} srcSet={`${img} 1x, ${img2x} 2x`} alt='' />
              </div>
            </Fade>
          </div>
        </Fade>
        <Stats content={sectionContent.stats} />
      </div>
    </section>
  );
};

export default AboutSection;
