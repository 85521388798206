import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Fluid from './lib/fluid';
import PrivacyPage from './pages/Privacy';

function App() {
  return (
    <div className={`wrapper`}>
      <Fluid />
      <div className={`content`}>
        <Header />

        <Suspense fallback={<div className=''></div>}>
          <Routes>
            <Route path='*' element={<HomePage />} />
            <Route path='privacy' element={<PrivacyPage />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default App;
