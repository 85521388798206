import { useEffect, useState } from 'react';
import { content } from '../../content';
import Button from '../Button';
import Logo from '../Logo';
import style from './Header.module.scss';
import { useMediaQuery } from 'usehooks-ts';
import { toAnchor } from '../../helpers/toAnchor';
import { Fade } from 'react-reveal';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollToFlag, setScrollToFlag] = useState(false);
  const [scrollPrev, setScrollPrev] = useState(0);
  const [scrolled, setScrolled] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:767px)');
  const navigate = useNavigate();
  const location = useLocation();

  const headerToggle = () => {
    window.addEventListener('scroll', () => {
      setScrolled(window.scrollY);

      if (scrolled > 100) {
        setIsScrolled(true);
        if (scrolled > scrollPrev) {
          setScrollToFlag(true);
        } else {
          setScrollToFlag(false);
        }
      } else {
        setIsScrolled(false);
      }

      if (location.pathname !== '/') {
        setIsScrolled(true);
      }

      setScrollPrev(scrolled);
    });
  };

  const toggleMenu = (type) => {
    const body = document.querySelector('body');
    if (type === 'close') {
      body.classList.remove('lock');
      setMenuOpen(false);
    } else {
      if (menuOpen) {
        setMenuOpen(false);
        body.classList.remove('lock');
      } else {
        setMenuOpen(true);
        body.classList.add('lock');
      }
    }
  };

  const toSection = (section) => {
    toggleMenu('close');
    navigate('/');
    setTimeout(() => {
      toAnchor(section);
    }, 100);
  };

  useEffect(() => {
    headerToggle();
  }, [scrolled]);

  useEffect(() => {
    setIsScrolled(location.pathname !== '/');
  }, [location]);

  return (
    <>
      <header
        className={`${style.header} ${isScrolled && style.scrolled} ${
          scrollToFlag && style.hidden
        }`}
      >
        <div className='container'>
          <div className={style.headerBody}>
            <Logo className={style.headerBody__logo} />

            {!isMobile && (
              <div
                className={`${style.headerBody__menu} ${
                  menuOpen && style.active
                }`}
              >
                {content.headerLinks.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => toSection(item.to)}
                    className={style.headerBody__menuItem}
                  >
                    {item.name}
                  </div>
                ))}
                {isMobile && (
                  <Button
                    onClick={() => toSection('form')}
                    title={'Связаться с нами'}
                    className={style.headerBody__btn}
                  />
                )}
              </div>
            )}

            {isMobile && (
              <div
                className={`${style.headerBody__burger} ${
                  menuOpen && style.active
                }`}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}

            {!isMobile && (
              <Button
                onClick={() => toSection('form')}
                title={'Связаться с нами'}
                className={style.headerBody__btn}
              />
            )}
          </div>
        </div>
      </header>

      {isMobile && (
        <div
          className={`${style.headerBody__menu} ${menuOpen && style.active}`}
        >
          {content.headerLinks.map((item, index) => (
            <div
              key={index}
              onClick={() => toSection(item.to)}
              className={style.headerBody__menuItem}
            >
              {item.name}
            </div>
          ))}
          {isMobile && (
            <Button
              onClick={() => toSection('form')}
              title={'Связаться с нами'}
              className={style.headerBody__btn}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Header;
