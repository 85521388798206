import { Fade } from 'react-reveal';
import style from './SectionTitle.module.scss';

const SectionTitle = ({ className, label, title }) => {
  return (
    <div className={`${style.info} ${className}`}>
      {label && (
        <Fade>
          <div className={style.info__label}>{label}</div>
        </Fade>
      )}
      {title && (
        <Fade>
          <div className={style.info__title}>{title}</div>
        </Fade>
      )}
    </div>
  );
};

export default SectionTitle;
