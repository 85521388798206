import { Fade } from 'react-reveal';
import style from './Card.module.scss';

const Card = ({ className, icon, title, descr }) => {
  return (
    <Fade>
      <div className={`${style.card} ${className}`}>
        <Fade>
          <div className={style.card__icon}>
            <span>
              <img src={icon} alt='' />
            </span>
          </div>
        </Fade>
        <Fade>
          <div className={style.card__title}>{title}</div>
        </Fade>
        <Fade>
          <div className={style.card__descr}>{descr}</div>
        </Fade>
      </div>
    </Fade>
  );
};

export default Card;
