import { Fade } from 'react-reveal';
import SectionTitle from '../../../../components/SectionTitle';
import { content } from '../../../../content';
import style from './Why.module.scss';
import Card from './components/Card';

const WhySection = () => {
  const sectionContent = content.homePage.whySection;

  return (
    <section id='why' className={style.why}>
      <div className='container'>
        <SectionTitle
          className={style.why__title}
          label={sectionContent.label}
          title={sectionContent.title}
        />
        <Fade bottom>
          <div className={style.whyBody}>
            {sectionContent.list.map((item, index) => (
              <Card
                key={index}
                icon={item.icon}
                title={item.title}
                descr={item.description}
              />
            ))}
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default WhySection;
