import { Fade } from 'react-reveal';
import style from './StatsCard.module.scss';
import { useAnimateNumber } from '../../../../../../hooks/useAnimationNumber';

const StatsCard = ({ icon, value, label }) => {
  const { ref, getValue } = useAnimateNumber(750);

  return (
    <div className={style.card} ref={ref}>
      <Fade>
        <div className={style.card__value}>
          <img src={icon} alt='' />
          <span className={style.num}>
            <span className={style.hidden}>
              <i>{value}</i>+
            </span>
            <span className={style.num__current}>{getValue(value)}</span>
          </span>
          {/* {getValue(value)}+<span className={style.hidden}>{value}+</span> */}
          {/* <span>{getValue(value)}</span>+ */}
        </div>
      </Fade>
      <Fade>
        <div className={style.card__label}>{label}</div>
      </Fade>
    </div>
  );
};

export default StatsCard;
