import style from './Button.module.scss';

const Button = ({ onClick, className, title }) => {
  return (
    <button onClick={onClick} className={`${style.button} ${className}`}>
      {title}
    </button>
  );
};

export default Button;
