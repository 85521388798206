import JellyButton from '../../../../components/JellyButton';
import { content } from '../../../../content';
import style from './Main.module.scss';
import { toAnchor } from '../../../../helpers/toAnchor';
import { Fade } from 'react-reveal';

const MainSection = () => {
  const sectionContent = content.homePage.mainSection;

  return (
    <section className={style.main}>
      <div className='container'>
        <div className={style.mainBody}>
          <Fade>
            <div className={style.mainBody__label}>{sectionContent.label}</div>
          </Fade>
          <Fade>
            <h2 className={style.mainBody__title}>{sectionContent.title}</h2>
          </Fade>
          <Fade>
            <JellyButton
              onClick={() => toAnchor('portfolio')}
              className={style.mainBody__btn}
              title={'Наши проекты'}
            />
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
