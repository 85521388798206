import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    IsBest: false,
    Name: 'Pixture',
    Type: 'html',
    Img: 'html/pixture',
    Link: 'https://demo.makhnev.site/pixture/',
  },
  {
    IsBest: false,
    Name: 'NFTop',
    Type: 'html',
    Img: 'html/nftop',
    Link: 'https://demo.makhnev.site/NFTop/',
  },
  {
    IsBest: false,
    Name: 'Rebank',
    Type: 'html',
    Img: 'html/rebank',
    Link: 'https://demo.makhnev.site/rebank/',
  },
  {
    IsBest: false,
    Name: 'Atlant',
    Type: 'html',
    Img: 'html/atlant',
    Link: 'https://demo.makhnev.site/inproc/sitemap.html',
  },
  {
    IsBest: false,
    Name: 'Yeezypay - collab',
    Type: 'react',
    Img: 'react/yeezypay-collab',
    Link: 'https://yeezypaycollab.makhnev.site/',
  },
  {
    IsBest: false,
    Name: 'Boddea',
    Type: 'html',
    Img: 'html/boddea',
    Link: 'https://demo.makhnev.site/boddea/',
  },
  {
    IsBest: false,
    Name: 'Open Travel',
    Type: 'html',
    Img: 'html/open_travel',
    Link: 'https://demo.makhnev.site/open_travel/',
  },
  {
    IsBest: true,
    Name: 'Rsh | Yandex',
    Type: 'react',
    Img: 'react/rshyandex',
    Link: 'https://rshyandex.makhnev.site/',
  },
  {
    IsBest: true,
    Name: 'Интертрейд Комодитиз',
    Type: 'turnkey',
    Img: 'turnkey/intertrade',
    Link: 'https://xn--d1aabambeaflwgsbxa5ack.xn--p1ai/',
  },
  {
    IsBest: true,
    Name: 'Фрост А',
    Type: 'turnkey',
    Img: 'turnkey/frosta',
    Link: 'https://xn--80a1afffi.xn--p1ai/',
  },
  {
    IsBest: true,
    Name: 'Школа английского языка "Черчилль"',
    Type: 'turnkey',
    Img: 'turnkey/cherchill',
    Link: 'https://demo.newa.agency/cherchill/',
  },
];

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    uploadProjectList: (state, action) => {
      state = action.payload;
    },
  },
});

export const { uploadProjectList } = projectsSlice.actions;

export default projectsSlice.reducer;
