const tgIcon = (
  <svg
    width='18'
    height='15'
    viewBox='0 0 18 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.3329 0.55055C17.3191 0.486815 17.2885 0.427904 17.2444 0.379831C17.2003 0.331759 17.1443 0.296248 17.082 0.276922C16.8552 0.232197 16.6206 0.248968 16.4025 0.325495C16.4025 0.325495 1.27089 5.76406 0.406832 6.36637C0.220095 6.4959 0.158029 6.57091 0.127266 6.65997C-0.0222308 7.08849 0.442991 7.27738 0.442991 7.27738L4.34287 8.54838C4.40875 8.55977 4.47637 8.5557 4.5404 8.53651C5.42767 7.9763 13.4665 2.89987 13.9312 2.72932C14.0041 2.7072 14.058 2.72932 14.0462 2.78329C13.8562 3.43417 6.91401 9.60349 6.87569 9.64127C6.85706 9.65656 6.84242 9.67613 6.83302 9.69831C6.82362 9.7205 6.81974 9.74463 6.82172 9.76864L6.45904 13.5741C6.45904 13.5741 6.30684 14.7614 7.49203 13.5741C8.33234 12.7327 9.13866 12.0365 9.54289 11.6981C10.884 12.6237 12.3272 13.6475 12.9495 14.1807C13.0539 14.2821 13.1777 14.3614 13.3134 14.4139C13.4492 14.4664 13.5941 14.491 13.7396 14.4862C14.3333 14.4635 14.4952 13.8142 14.4952 13.8142C14.4952 13.8142 17.252 2.72069 17.3448 1.23381C17.3534 1.08809 17.3659 0.994724 17.3669 0.894879C17.3719 0.779075 17.3604 0.663156 17.3329 0.55055Z'
      fill='#657791'
    />
  </svg>
);

export default tgIcon;
